<template lang="pug">
.main-page.row
  el-form(:inline="true" :model="searchForm")
    el-form-item(label="手机号")
      el-input(v-model="searchForm.phone")
    el-form-item(label="人员姓名")
      el-input(v-model="searchForm.name")
    el-form-item
      el-button(type="primary" @click="refresh" v-loading="loading") 查询
      el-button(type="success" @click="handleNew" v-if="pemission['2.1.1']") 新建用户

  el-table(
    :data="tableData"
    stripe
    fit
    width="800"
    v-loading="loading"
  )
    el-table-column(prop="phone" label="手机号")
    el-table-column(prop="name" label="人员姓名")
    el-table-column(prop="username" label="登录账号")
    el-table-column(prop="ctime" label="注册时间")
    el-table-column(prop="last_login_time" label="最后登录时间")
    el-table-column(prop="depart" label="所属部门")
    el-table-column(prop="role_name" label="角色")
    el-table-column(label="操作" fixed="right" width="260" align="center")
      template(slot-scope="scope")
        el-button(
          @click="handleClick(scope.row, 'edit')"
          type="primary" size="small"
        ) 编辑
        el-button(
          @click="handleClick(scope.row, 'disable')"
          type="warning" size="small"
          v-if="pemission['2.1.2'] && scope.row.status == 1"
        ) 禁用
        el-button(
          v-if="pemission['2.1.3'] && scope.row.status != -1"
          @click="handleClick(scope.row, 'delete')"
          type="danger"
          size="small"
        ) 删除

  el-pagination(
    background
    layout="prev, pager, next, total"
    :page-size="pageSize"
    :total="total"
    @current-change="pageChange"
  )

  el-dialog(
    title="生成人员"
    :visible.sync="createVisible"
    width="50%"
    :show-close="false"
  )
    .row(v-if="createVisible")
      el-form(
        :model="createForm"
        ref="createForm"
        label-width="120px"
        :rules="rules"
      )
        el-row
          el-col(:span="12")
            el-form-item(label="登录账号" prop="username")
              el-input(v-model="createForm.username")
          el-col(:span="12" v-if="showPassword")
            el-form-item(label="密码" prop="password")
              el-input(v-model="createForm.password" show-password)
        el-row
          el-col(:span="12")
            el-form-item(label="人员姓名" prop="name")
              el-input(v-model="createForm.name")
          el-col(:span="12")
            el-form-item(label="手机号" prop="phone")
              el-input(v-model="createForm.phone")
        el-row
          el-col(:span="12")
            el-form-item(label="人员角色" prop="role_id")
              RoleSelect(v-model="createForm.role_id")
          el-col(:span="12")
            el-form-item(label="所属部门" prop="depart")
              el-input(v-model="createForm.depart")

    span(slot="footer" class="dialog-footer")
      el-button(@click="createVisible = false") 取 消
      el-button(
        type="primary"
        @click="handleCreate"
      ) 保存
</template>

<script>
import RoleSelect from '@/components/RoleSelect.vue';
import { generateTypes, getPemission, getUserInfo } from '@/utils/const';
import request from '@/utils/request';

export default {
  components: {
    RoleSelect,
  },
  data() {
    return {
      pemission: getPemission(),
      generateTypes,
      searchForm: {
        phone: '',
        name: '',
      },
      loading: false,
      tableData: [],
      total: 0,
      pageSize: 20,
      pageIndex: 1,
      multipleSelection: [],
      createVisible: false,
      createForm: {},
      rules: {
        username: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur',
          },
        ],
        role_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        depart: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      isEdit: false,
      showPassword: false,
    };
  },
  methods: {
    handleNew() {
      this.createForm = {};
      this.isEdit = false;
      this.createVisible = true;
    },
    handleCreate() {
      // console.log(this.$refs.createForm);
      this.$refs.createForm.validate((valid) => {
        if (valid) {
          if (+this.createForm.phone[0] !== 1) {
            this.$message.error('请输入正确的手机号');
            return;
          }
          this.doSubmit();
        }
      });
    },
    doSubmit() {
      this.loading = true;
      request({
        method: 'post',
        url: `/manager/user/${this.isEdit ? 'edit' : 'add'}`,
        data: this.createForm,
      }).then((res) => {
        if (res.code === 1000) {
          this.createVisible = false;
          this.refresh();
        } else {
          this.$message.error(res.msg);
        }
      })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
    handleClick(row, target) {
      if (target === 'edit') {
        const sameUser = row.username === getUserInfo().username;
        this.createForm = {
          id: row.id,
          name: row.name,
          username: row.username,
          phone: row.phone,
          role_id: row.role_id,
          depart: row.depart,
          password: sameUser ? '123456' : '',
        };
        if (sameUser) {
          this.showPassword = true;
        }
        this.createVisible = true;
        this.isEdit = true;
        return;
      }
      this.$confirm(`此操作将永久${target === 'delete' ? '删除' : '禁用'}该人员, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(() => {
        this.doDelete(row.id, target === 'delete');
      }).catch(() => {
        this.$message.info('已取消');
      });
    },
    doDelete(id, isDelete) {
      this.loading = true;
      request({
        method: 'post',
        url: `/manager/user/${isDelete ? 'delete' : 'forbid'}`,
        data: { uid: id },
      }).then((res) => {
        if (res.code === 1000) {
          this.refresh();
        } else {
          this.$message.error(res.msg);
        }
      })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
    pageChange(e) {
      this.pageIndex = e;
      this.search();
    },
    refresh() {
      this.pageIndex = 1;
      this.search();
    },
    search() {
      const params = {
        ...this.searchForm,
        page: this.pageIndex,
        pagesize: this.pageSize,
      };
      this.getData(params);
    },
    getData(params) {
      this.loading = true;
      request({
        method: 'get',
        url: '/manager/user/getList',
        params,
      }).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.count;
          this.tableData = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style>

</style>
