<template lang="pug">
  BaseSelect(
    v-model="data"
    :list="list"
    @input="handleChange"
    clearable
    :loading="loading"
    filterable
  )
</template>

<script>
import BaseSelect from './BaseSelect.vue';
import { getRoleInfos } from '@/utils/baseData';

export default {
  components: {
    BaseSelect,
  },
  props: ['value'],
  data() {
    return {
      list: [],
      data: this.value,
      loading: false,
    };
  },
  watch: {
    value() {
      this.data = this.value;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e);
    },
  },
  mounted() {
    this.loading = true;
    getRoleInfos()
      .then((infos) => {
        const array = infos.list || [];
        this.list = array.map((e) => ({
          label: e.role_name,
          value: e.id,
        }));
        this.loading = false;
      });
  },
};
</script>
